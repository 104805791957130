
/*
|--------------------------------------------------------------------------
| Custom Javascript code
|--------------------------------------------------------------------------
|
| Now that you configured your website, you can write additional Javascript
| code inside the following function. You might want to add more plugins and
| initialize them in this file.
|
*/
var PDimensions = {
 width: 210,
 height: 297,
},
params = {
  api: '/api/index.php/',
  qrwidth: 30, //mm
  qrpos: {
    x: 150,
    y: 200,
  },
},
$previewcontainer = $('#factuurpreview');

//On document Load
$(function() {

  //Initiate Dropzone to allow files uploaded to server
  $("#dropzone").dropzone({
    url: params.api + 'parse',
    acceptedFiles: "application/pdf",
    maxFilesize: 2, // MB
    accept: function(file, done) {
        showLoader(true);
        done();
    },
    success: function(file, response){
      hideErrors();
      if (response.hasOwnProperty('error')) {
        showError(response.error);
        showLoader(false);
        return;
      }
      handlePDFFile(file, response);
      addInvoiceForm(response);
    }
  });

  //setup listeners for preview (onchange events etc)
  setupPreviewListeners();

  //initiate preview with data from Local Storage
  initiatePreview();

  //Reset Preview window with all data in right position
  resetPreview();


  $('form#generateInvoiceForm').on('submit', function (e) {

      e.preventDefault();
      var uploaded = false;
      var hasError = false;

      hideErrors();
      $('.is-invalid').removeClass('is-invalid');

      //Validate form
      if (!$('input[name="secret"]').val()) {
        hasError = true;
        showError('PAY secret is verplicht!');
        $('input[name="secret"]').addClass('is-invalid');
      }

      if (!$('input[name="serviceid"]').val()) {
        hasError = true;
        showError('PAY service ID is verplicht!');
        $('input[name="serviceid"]').addClass('is-invalid');
      }

      //Check if there are uploaded invoices
      $('form#generateInvoiceForm .reference').each(function( index ){
        uploaded = true;
        if (!$(this).val()) {
          hasError = true;
          $(this).addClass('is-invalid');
        }
      });

      $('form#generateInvoiceForm .amount').each(function( index ){
        if (!$(this).val()) {
          hasError = true;
          $(this).addClass('is-invalid');
        }
      });
      if (!uploaded) {
        hasError = true;
        showError('Vergeet niet ten minste een factuur te uploaden.');
      }
      if (hasError) {
        return false;
      }
      //Show loader
      showLoader(true);

      //Make Ajax Request to server and get data to download link
      $.ajax({
        type: 'post',
        url: params.api + 'generate',
        data: $('form#generateInvoiceForm').serialize(),
        dataType: 'json',
        cache: false,
        success: function (data) {
          //hide Loader
          showLoader(false);

          if (data.hasOwnProperty('error')) {
            showError(data.error);
          }else{

            //Empty Form and show success message and download file
            var url = data.url;
            $('form .invoiceForm').each(function(index){
              var uuid = $(this).attr('data-uuid');
              $('#invoice_' + uuid).remove();
              $(this).remove();
            })
            $('.nav-tabs-minimal li.nav-item:first a').tab('show');
            //Mocht er wat mis gaan, kun je hem via <a target="_blank" href="'+url+'">deze link</a> nog eens downloaden;
            $success = '<div class="alert alert-success formErrors">Je bestand is succesvol gegenereerd en wordt gedownload.</div>';
            $('form .tab-content').append($success);

            downloadFile(url);
          }
        },
        error: function (xhr, ajaxOptions, thrownError) {
          showError('Er gaat iets mis, probeer het nog eens');
          showLoader(false);
        }
      });

    });


  //API Documentation
  $('.launchApiModel').click(function(e){
    //Get Params
    var params = {
      secret: $('input[name="secret"]').val(),
      serviceId: $('input[name="serviceid"]').val(),
      qrwidth: $('input[name="qrwidth"]').val(),
      qrpos: $('input[name="qrpos"]').val(),
      qrtype: $('select[name="qrtype"]').val(),
      invoice: 'HIER_KOMT_JE_BASE64_ENCODED_PDF',
    };
    $('#json-params').text(JSON.stringify(params));
    $('#apiModal').modal('show')
  });

  //Format 2 decimals
  $('body').on('change','.2decimals', function() {
    format2dec($(this));
  });

});

function showLoader(bool){
  if (bool) {
    $('.rd-loader').show();
  }else{
    $('.rd-loader').hide();
  }
}

function downloadFile(download_url){
  $.fileDownload(download_url, {
      successCallback: function (url) {
        alert('suucess');
        hideErrors();
      },
      failCallback: function (html, url) {
        showError('Er is iets mis gegaan met het downloaden van je bestand');
      }
  });
}

function format2dec(el) {
  var curr_val = parseFloat($(el).val());
  $(el).val(curr_val.toFixed(2));
}

function hideErrors(){
  $('.formErrors').hide().empty();
}
function showError(error){
  $('.formErrors:first').append('<p>' + error + '</p>').show();
}

$(window).on('resize', function(){
  //Reset Preview window with all data in right position
  resetPreview();
});

/*
 * Initiates preview with stored data from localStorage
 */
function initiatePreview(){
    //Hide factuur(en) tab
    $('#factuur-tab').hide();

    //Get data from Local Storage
    var qrpos = getItem('qrpos');
    if (qrpos) {
      qrpos = JSON.parse(qrpos);
      params.qrpos = qrpos;
    }

    var qrwidth = getItem('qrwidth');
    if (qrwidth) {
      params.qrwidth = qrwidth;
    }

    //Setup Data in Input Fields
    $('input[name="qrpos"]').val(params.qrpos.x + 'x' + params.qrpos.y)
    $('input[name="qrwidth"]').val(params.qrwidth)

    //PAY credentials stored in localstorage
    var secret = getItem('secret')
    var serviceid = getItem('serviceid');
    if (secret !== null && serviceid != null) {
      $('input[name="secret"]').val(secret)
      $('input[name="serviceid"]').val(serviceid)
      $('input[name="storepay"]').attr('checked', 'checked')
    }

    //Extra input items
    var inputs = ['qrtype'];
    for (var i = 0; i < inputs.length; i++) {
      type = inputs[i];
      $('*[name="'+type+'"]').val(getItem(type)).trigger('change');
    }
}

/*
 * Setup listeners to store data from form
 */
function setupPreviewListeners(){

  $('input[name="secret"], input[name="serviceid"]').on('change', function() {
      if ($('input[name="storepay"]:checked').length > 0) {
        storeItem($(this).attr('name'), $(this).val());
      }
  });

  $('input[name="storepay"]').on('change', function() {
      if( this.checked ) {
        storeItem('secret', $('input[name="secret"]').val());
        storeItem('serviceid', $('input[name="serviceid"]').val());
      }else{
        removeItem('secret');
        removeItem('serviceid');
      }
  });

  //Change Type field, toggle info window
  $('select[name="qrtype"]').on('change', function() {
    $('.dragQR').removeClass('single right bottom').addClass($(this).val());
  });

  //Store other inputs
  $('.storeField').on('change', function() {
    storeItem($(this).attr('name'), $(this).val());
  });

  //Toggle visibility invoices
  $('#invoiceFormContent').on('click', 'span.toggleInvoice', function() {
    //Toggle invoice uuid, get uuid from parent div
    var uuid = $(this).closest('.invoiceForm').attr('data-uuid');
    showInvoice(uuid);
  });

  $('#invoiceFormContent').on('click', 'span.removeInvoice', function() {
    //Toggle invoice uuid, get uuid from parent div
    var parent = $(this).closest('.invoiceForm');
    var uuid = parent.attr('data-uuid');
    $('#invoice_' + uuid).remove();
    parent.remove();
    $("#factuurpreview canvas").hide();
    $('#factuurpreview canvas:first').show();

  });
}

/*
 * resets preview window with QRCode
 * 1) Dimensions of preview container
 * 2) Size of QRCode
 * 3) Position of QRCode
 */
function resetPreview(){

  //Set dimensions previewcontainer
  width = $previewcontainer.width();
  $previewcontainer.height( (PDimensions.height / PDimensions.width) * width );

  $qrcodes = document.getElementsByClassName("qrcode");
  for (var i = 0; i < $qrcodes.length; i++) {
    qrcode = $qrcodes[i];

    //Set Width QRCode relative to previewcontainer
    qrwidth = parseFloat(qrcode.getAttribute('data-qrwidth')) || params.qrwidth;
    qrwidthpx  = qrwidth * width / PDimensions.width;
    qrcode.style.width = qrwidthpx + 'px'
    qrcode.style.height = qrwidthpx + 'px'
    qrcode.setAttribute('data-qrwidth', qrwidth);

    //Set Font Size parent dragQR
    styleQRContainer();

    //Position parent Element
    target = qrcode.parentElement.parentElement;
    xpos = parseFloat(target.getAttribute('data-x-pos')) || params.qrpos.x;
    ypos = parseFloat(target.getAttribute('data-y-pos')) || params.qrpos.y;
    //Map mm to pixels
    x = (xpos / PDimensions.width) * width;
    y = (ypos / PDimensions.width) * width;
    moveElement(target, x, y)
  }

}

function addInvoiceForm(data){
  $('#factuur-tab').show();
  $('#factuur-tab a').tab('show');

  $template = $("#invoiceTemplateForm").clone();
  $template
    .removeClass('d-none')
    .attr('id', '')
    .attr('data-uuid', data.uuid);
  //$template.attr('id', 'tab_'+data.uuid);
  $template.find('.invoiceTitle .title').text(data.filename);
  $template.find('.filename').attr('name', 'filename['+data.uuid+']').val(data.filename);

  $template.find('.amount').attr('name', 'amount['+data.uuid+']');
  if (data.amount) {
    amount = parseFloat(data.amount);
    $template.find('.amount').val(amount.toFixed(2));
  }else{
    $template.find('.amount').addClass('is-invalid');
  }

  $template.find('.reference').attr('name', 'reference['+data.uuid+']');
  if (data.reference) {
    $template.find('.reference').val(data.reference);
  }else{
    $template.find('.reference').addClass('is-invalid');
  }
  //Add Template
  $('#invoiceFormContent').append($template);
}


function handlePDFFile(file, data){
  var dimensions = [210, 290];

  var reader = new FileReader();
  reader.onload = (function(reader) {
  return function() {
    var contents = reader.result;
    var loadingTask = pdfjsLib.getDocument(contents);

    loadingTask.promise.then(function(pdf) {
      var pageNumber = 1;
      pdf.getPage(pageNumber).then(function(page) {
        var scale = 1;
        var viewport = page.getViewport({
          scale: scale,
        });

        var canvas = document.createElement('canvas');
        canvas.id = "invoice_" + data.uuid;
        var context = canvas.getContext('2d');

        canvas.width = viewport.width;
        canvas.height = viewport.height;

        var renderContext = {
          canvasContext: context,
          viewport: viewport
        };
        page.render(renderContext);

        //Append Canvas to previewcontainer
        var previewappend = document.getElementById("factuurpreview");
        previewappend.appendChild(canvas);

        showInvoice(data.uuid);
        showLoader(false);
      });
    });
  }
  })(reader);
  pdf = reader.readAsDataURL(file);
}


interact('.draggable')
  .draggable({
    inertia: false,
    modifiers: [
      interact.modifiers.restrict({
        restriction: "parent",
        elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
        endOnly: true
      })
    ],
    autoScroll: true,
    listeners: {
      move (event) {
        var target = event.target,
            // keep the dragged position in the data-x/data-y attributes
            x = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx,
            y = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

        moveElement(target, x, y);
      },
      end (event) {

        //Store QRWidth in LocalStorage
        var target = event.target,
        x = (parseFloat(target.getAttribute('data-x-pos')).toFixed(2) || 0),
        y = (parseFloat(target.getAttribute('data-y-pos')).toFixed(2) || 0);
        storeItem('qrpos', JSON.stringify({x: x, y: y}));

      }
    },
  });

interact('.resizable')
  .resizable({
    edges: { left: false, right: true, bottom: true, top: false },
    listeners: {
      move (event) {
        var target = event.target

        if (event.rect.width < 20) {
          return;
        }
        // update the element's style
        target.style.width = event.rect.width + 'px'
        target.style.height = event.rect.height + 'px'

        //Map pixels to mm
        qrwidth = (event.rect.width / $previewcontainer.width()) * PDimensions.width;
        target.setAttribute('data-qrwidth', qrwidth);
        $('input[name="qrwidth"]').val( qrwidth.toFixed(2) );


      },
      end (event) {
        //Store QRWidth in LocalStorage
        var target = event.target,
        qrwidth = parseFloat(target.getAttribute('data-qrwidth')).toFixed(2) || 0;
        if (qrwidth > 0) {
          storeItem('qrwidth', qrwidth);
        }

        //Set Font Size parent dragQR
        styleQRContainer();
      }
    },
    modifiers: [
      // keep the edges inside the parent
      interact.modifiers.restrictEdges({
        outer: 'parent.parent'
      }),
      interact.modifiers.aspectRatio({
        // make sure the width is always double the height
        ratio: 1,
        // also restrict the size by nesting another modifier
        modifiers: [
          interact.modifiers.restrictSize({ max: 'parent.parent' })
        ]
      }),
    ],
    inertia: true
});

function styleQRContainer(){
  var width = $('img.qrcode').width();
  $('.dragQR').css({ 'font-size': width / 5 + 'px' });
}
/**
 * Move Element on drag and resize
 */
function moveElement(target, x, y){
  // translate the element
  target.style.webkitTransform =
  target.style.transform ='translate(' + x + 'px, ' + y + 'px)';

  // update the posiion attributes
  target.setAttribute('data-x', x);
  target.setAttribute('data-y', y);

  //Mapping to mm
  var Y = ( (y / $previewcontainer.height()) * PDimensions.height );
  var X = ( (x / $previewcontainer.width()) * PDimensions.width ) ;
  //var input = target.getAttribute('data-target');
  $('input[name="qrpos"]').val( X.toFixed(2) + "x" + Y.toFixed(2));

  target.setAttribute('data-x-pos', X);
  target.setAttribute('data-y-pos', Y);
}


function showInvoice(uuid){
  $("#factuurpreview canvas").hide();
  $('#invoice_' + uuid).show();
}


/*
 * Store item in Local Storage
 */
function storeItem(item, value){
  if(_support_html5_storage()){
    localStorage.setItem(item,value);
  }
}

//Get Item from Local Storage
function getItem(item){
  if(_support_html5_storage()){
    return localStorage.getItem(item);
  }
  return null;
}

//Get Item from Local Storage
function removeItem(item){
  if(_support_html5_storage()){
    localStorage.removeItem(item);
  }
}

/*function to check HTML5 storage support in user browser*/
function _support_html5_storage() {
    try {
      return 'localStorage' in window && window['localStorage'] !== null;
    } catch (e) {
      return false;
    }
}
